import {account} from "./initAppwrite";
import {ID} from "appwrite";

export default async function makeVerification(name, email) {
    try {
        try {
            //This is added because appwrite uses cookies across the browser
            //so a user would be logged into the dashboard (though without any data privileges)
            //if a survey was made in the same browser before. Concerns mainly the merchant.
            //This does mean that you are logged out of the dashboard if you were previously logged in.
            await account.get();
            await account.deleteSessions();
        } catch(e) {
            console.log("No sessions active: ", e);
        }
        //TODO-FOR-UPDATE: make in cloud
        const password = ID.unique();
        const user = await account.create(ID.unique(), email, password, name);
        const userSession = await account.createEmailPasswordSession(email, password);

        /*const anonymous = await account.createAnonymousSession();
        console.log("anonymous user created with id: ", anonymous.userId);

        const updated_name = await account.updateName(name);
        console.log("name updated: ", updated_name);

        const updated_email = await account.updateEmail(email, ID.unique());
        console.log("email updated: ", updated_email);*/

        const verification = await account.createVerification("https://gewinnspiel-lederwarenacker.de/verify");
        console.log("verification email sent: ", verification);

        await account.deleteSession('current');

        //return anonymous.userId;
        return user.$id;
    } catch(e) {
        console.log("Error setting up verification process: ", e);
        return false;
    }
}